const w = window;

export class LazyLoader {

	bundleUrl;
	config;
	namespace;
	bundleLoaded;
	initInvoked;
	queue = [];
	onLoad = [];

	constructor(bundleUrl: string, config: Object, namespace: string = 'Sentry') {
		this.bundleUrl = bundleUrl;
		this.config = config;
		this.namespace = namespace;

		this.emulateGlobal();
		this.bindGlobal();
	}

	emulateGlobal() : void {
		const functions = 'init addBreadcrumb captureMessage captureException captureEvent configureScope withScope showReportDialog';

		w[this.namespace] = w[this.namespace] || {};

		for (const name of functions.split(' ')) {
			w[this.namespace][name] = (...args) => {
				if (name.indexOf('capture') !== -1 || name === 'showReportDialog') {
					this.loadBundle();
				}

				this.enqueue({ f: name, a: args });
			};
		}

		w[this.namespace].onLoad = (callback) => {
			this.initInvoked ? callback() : this.onLoad.push(callback);
		};

		w[this.namespace].forceLoad = () => {
			setTimeout(() => { this.loadBundle(); });
		};
	}

	bindGlobal() : void {
		w.addEventListener('error', this.onError);
		w.addEventListener('unhandledrejection', this.onUnhandledRejection);
	}

	unbindGlobal() : void {
		w.removeEventListener('error', this.onError);
		w.removeEventListener('unhandledrejection', this.onUnhandledRejection);
	}

	onError = (...args) => {
		this.loadBundle();
		this.enqueue({ e: args });
	}

	onUnhandledRejection = (p) => {
		this.loadBundle();
		this.enqueue({ p: p });
	}

	enqueue(item: Object) : void {
		this.queue.push(item);
	}

	isQueueFunction(item: Object) : boolean {
		return ('f' in item);
	}

	isQueueError(item: Object) : boolean {
		return 'e' in item;
	}

	isQueuePromiseRejection(item: Object) : boolean {
		return 'p' in item;
	}

	loadBundle() : void {
		if (this.bundleLoaded != null) { return; }

		this.bundleLoaded = false;

		const firstScriptTagInDom = w.document.scripts[0];
		const cdnScriptTag = w.document.createElement('script');

		cdnScriptTag.src = this.bundleUrl;
		cdnScriptTag.crossOrigin = 'anonymous';
		// Once our SDK is loaded
		cdnScriptTag.addEventListener('load', this.onBundleScriptLoad, {
			once: true,
			passive: true,
		});

		firstScriptTagInDom.parentNode.insertBefore(cdnScriptTag, firstScriptTagInDom);
	}

	onBundleScriptLoad = () : void => {
		try {
			this.bundleLoaded = true;
			this.initInvoked = false;

			const SDK = w[this.namespace];
			const oldInit = SDK.init;

			w.SENTRY_SDK_SOURCE = 'loader';

			SDK.init = (options) => {
				this.initInvoked = true;
				options = Object.assign({}, this.config, w.SentryConfig || {}, options);

				this.unbindGlobal();
				this.setupDefaultIntegrations(options, SDK);

				oldInit.call(SDK, options);
			};

			setTimeout(() => this.setupSDK(SDK));
		} catch (e) {
			console.error(e);
		}
	}

	setupDefaultIntegrations(config: Object, SDK: Object) : void {
		const integrations = config.integrations || [];

		if (!Array.isArray(integrations)) { return; }

		const integrationNames = integrations.map(function (integration) { return integration.name; });

		if (config.tracesSampleRate && integrationNames.indexOf('BrowserTracing') === -1) {
			integrations.push(SDK.browserTracingIntegration({ enableInp: true }));
		}

		if ((config.replaysSessionSampleRate || config.replaysOnErrorSampleRate) && integrationNames.indexOf('Replay') === -1) {
			integrations.push(SDK.replayIntegration());
		}

		config.integrations = integrations;
	}

	setupSDK(SDK: Object) : void {
		try {
			if (typeof w.sentryOnLoad === 'function') {
				w.sentryOnLoad();
				w.sentryOnLoad = undefined;
			}

			for (const onLoad of this.onLoad.splice(0)) {
				if (typeof onLoad === 'function') { onLoad(); }
			}

			for (const item of this.queue) {
				if (this.isQueueFunction(item) && item.f === 'init') {
					SDK.init.apply(SDK, item.a);
				}
			}

			if (!this.initInvoked) {
				SDK.init();
			}

			const errorHandler = w.onerror;
			const promiseRejectionHandler = w.onunhandledrejection;

			for (const item of this.queue.splice(0)) {
				if (this.isQueueFunction(item)) {
					if (item.f === 'init') { continue; }

					SDK[item.f].apply(SDK, item.a);
				} else if (this.isQueueError(item) && errorHandler) {
					errorHandler.apply(w, item.e);
				} else if (this.isQueuePromiseRejection(item) && promiseRejectionHandler) {
					promiseRejectionHandler.call(w, item.p);
				}
			}
		} catch (e) {
			console.error(e);
		}
	}

}